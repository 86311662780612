// Here you can add other styles
.bg-light {
  background-color: #2d9aeb !important;
}

.navbar a {
  color: #fff;
}

.profile .nav-tabs a {
  width: 33.3%;
}

.login {
  background-color: #f5f5f5;
}

.login .logo {
  background-color: #2d9aeb;
  padding: 15px;
  border-radius: 5px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.main-content {
  margin: 10px 5px;
}

table a {
  color: #2d9aeb;
}

.setbgcolor {
  background-color: #2d9aeb;
}

.err_below {
  color: red;
  font-size: 11px;
}

.nav-link {
  display: block;
  padding: 0.5rem 0.3rem !important;
}

.navbar-expand {
  font-size: 14px !important;
}

.invoice {
  float: right;
  margin: -205px 166px 30px 0px;
  font-size: 14px;
}

.addrleft {
  display: block;
  /* block-size: 114px; */
  width: 35%;
  margin: 0px 0px 32px 152px;
  font-size: 14px;
}

.crstx {
  margin: 8px 0px 0px 0px;
}

.textsize {
  width: 100%;
}

table {
  // width: 35%;
  border-collapse: collapse;
  border-spacing: 0;
}

.invoice th {
  text-align: center;
  background-color: #ddd;
  padding: 7px;
  border-bottom: 1px solid#CCCCCC;
  border-right: 1px solid#CCCCCC;
  background-color: #ddd;
}

table td {
  padding: 5px;
  border-bottom: 1px solid#CCCCCC;
  /* border-right: 1px solid#CCCCCC; */
}

.invoicedata {
  background-color: #eee;
}

.broucherbtn {
  margin: 0 0 0 361px;
}

.errormsg {
  color: red;
}

/* .broucherbtn{
    margin: 24px 3px 2px -181px;

} */
.btn {
  margin: 0 0 17px 0px;
}

.table td {
  vertical-align: none;
}

.url-shorten-prefix {
  margin-bottom: 0;
  background: #9f9f9f45;
  position: absolute;
  padding: 6px 30px;
  right: -20px;
  height: 35px;
  top: 29px;
  font-weight: 600;
  border-radius: 5px;
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 6px;
  color: #20a8d8;
  border: none;
  background: none;
  outline: none;
}

.clear-button:focus {
  outline: none;
}

.clear-button-departure {
  right: 13px !important;
  top: 35px !important;
}

.collapse-content {
  border: 1px solid #ddd;
  padding: 10px;
  transition: height 0.3s ease;
}

.collapse-content.collapsing {
  height: 0;
  overflow: hidden;
}

.filter-button {
  margin-left: 15px;
}

.filter-notification {
  position: absolute;
  right: 5px;
  top: -12px;
  background: white;
  color: black;
  border: 1px solid black;
  border-radius: 24px;
  width: 24px;
  padding: 1px;
}

.close-button {
  position: absolute;
  right: -1px;
  top: -1px;
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 24px;
  width: 24px;
  padding: 1px;
}

.discount-table-data {
  max-width: 150px;
  word-wrap: break-word;
}

.discount-table-data-utm {
  max-width: 85px;
  word-wrap: break-word;
}

.ml-15 {
  margin-left: 15px;
}

.dropdown-details {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.flex-content-end {
  display: flex;
  justify-content: flex-end;
}

.flex-content-between {
  display: flex;
  justify-content: space-between;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.pagination-margin {
  margin: 0 5px 17px 0;
}

.pagination-margin2 {
  margin: 0 0 17px 5px;
}

.loader {
  height: 400px;
}

.loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-loader-div .spinner-border {
  width: 24px;
  height: 24px;
}

.limit-dropdown .dropdown-menu {
  width: auto;
  min-width: 100%;
  right: 0;
  left: auto;
}

.table-input {
  width: 70px;
  border: 2px solid #20a8d8;
  outline: none;
  border-radius: 0.25rem;
  padding: 2px 4px;
}
